<template>
	<div class="app-container">
		<el-card class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>查询条件</span>
				<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm')" size="small">清空</el-button>
				<el-button style="float: right" @click="searchTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" ref="searchForm" :model="searchForm" size="small" label-width="100px">
					<el-form-item label="房间名称：" prop="name">
						<el-input v-model="searchForm.name" placeholder="请输入房间名称"></el-input>
					</el-form-item>
					<el-form-item label="是否启用：" prop="status">
						<el-select v-model="searchForm.status" clearable placeholder="请选择" autocomplete="off" style="max-width: 200px;">
						    <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="赛事名称" prop="gameName">
						<el-select filterable clearable v-model="searchForm.gameName" placeholder="请选择" autocomplete="off" style="max-width: 200px;">
							<el-option v-for="item in gameName" :key="item.id" :label="item.gameName" :value="item.gameName"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-row>
			<!-- <el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="append()">新增</el-button> -->
			<el-popover placement="bottom" width="150" v-model="visible" style="float: right;">
				<el-checkbox-group v-model="checkList" @change="checkedChange">
				    <el-checkbox v-for="item in checkArr" :label="item.val">{{item.label}}</el-checkbox>
				</el-checkbox-group>
				<el-button type="primary" size="medium" icon="el-icon-s-grid" circle slot="reference"></el-button>
			</el-popover>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="page = 1;getList()"></el-button>
		</el-row>
		<el-card>
			<el-table :data="tableData" :stripe="true" border v-loading="listLoading" style="width: 100%" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column v-for="item in checkArr" v-if="item.dis" :prop="item.val" :label="item.label" min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'status'">{{scope.row.status == 0 ? '禁用' : '启用'}}</span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="95" fixed="right">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" icon="el-icon-edit" @click="gameClick(scope.row)">玩家</el-button>
					</template>
				 </el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChange" :current-page="page" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog width="80%" title="玩家详情" :visible.sync="dialogFormVisibles" :append-to-body="true">
			<gameRoom :key="timeKey" :gameRefId="gameRefId" :roomId="roomId"></gameRoom>
		</el-dialog>
	</div>
</template>

<script>
	import gameRoom from "../../../components/gameRoom"
	export default {
		data() {
			return {
				page: 1,
				searchForm: {
					name: '',
					status: 1,
					gameName: ''
				},//条件查询form
				statusArr: [{
					value: 0,
					label: "禁用"
				}, {
					value: 1,
					label: "启用"
				}],//是否启用
				gameName: [],
				tableData: [],//表格
				multipleSelection: [],//表格选中数组
				
				total: 0,//分页总条数
				visible: false,
				checkArr: [
					{label: "房间名称", val: "name", dis: true},
					{label: "发起人昵称", val: "niceName", dis: true},
					{label: "赛事名称", val: "gameName", dis: true},
					{label: "容纳人数", val: "num", dis: true},
					{label: "执行顺序", val: "sort", dis: true},
					{label: "是否启用", val: "status", dis: true}
				],
				checkList: ["name", "niceName", "gameName", "num", "sort", "status"],
				dialogFormVisibles: false,//玩家详情弹窗
				gameRefId: '',
				roomId: '',
				timeKey: ''
			}
		},
		components: {
			gameRoom
		},
		created(){
			this.getList();
			this.getgameName();
		},
		methods:{
			getList() {
				this.listLoading = true;
				// 查询内容转数组
				let obj = this.searchForm;
				let arr = Object.keys(obj);
				let arrs = [];
				arr.forEach(item => {
					let o = {};
					o.col = item;
					o.val = obj[item];
					if(item == "status") {
						o.type = "=";
					} else {
						o.type = "like";
					}
					arrs.push(o);
				})
				
				let data = {
					entity: 'FaExamRoom',
					page: this.page,
					pageSize: 10,
					filter: JSON.stringify(arrs),
					sidx: 'sort',
					sord: 'asc'
				}
				this.$comjs.ajax.getAjax('/jqGrid/faCommonlist', data, this, res => {
					this.tableData = res.rows;
					this.total = res.total;
					this.listLoading = false;
				});
			},
			getgameName() {
				let data = {
					entity: 'FaExamRoom'
				}
				this.$comjs.ajax.getAjax('/jqGrid/findList', data, this, res => {
					this.gameName = res.list
					const arr = this.gameName
					const map = new Map()
					const name = arr.filter(item => !map.has(item.gameName) && map.set(item.gameName, 1))
					this.gameName = name
				})	
			},
			// 搜索
			searchTable() {
				this.page = 1;
				this.getList();
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.page = 1;
				this.getList();
			},
			// 新增
			// append() {
			// 	this.dialogFormVisible = true;
			// 	this.dialogTitle = "新增数据";
			// 	this.form = {
			// 		id: '',
			// 		name: '',
			// 		remark: '',
			// 		weight: []
			// 	}
			// },
			// 表格选中
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			// 多选框
			checkedChange(value) {
				let arr = this.checkArr;
				arr.forEach(item => {
					item.dis = false;
				})
				value.forEach(item => {
					let index = arr.findIndex(d => d.val === item);
					arr[index].dis = true;
				})
				this.checkArr = arr;
			},
			// 玩家
			gameClick(row) {
				this.timeKey = new Date().getTime();
				this.gameRefId = row.gameRefId;
				this.roomId = row.id;
				this.dialogFormVisibles = true;
			}
		},
		computed:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.app-container {
		padding: 20px;
	}
	.elCard {
		margin-top: 20px;
	}
	.el-row {
		padding: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
</style>

